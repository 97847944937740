<script>
import { spamHints } from '@/shared/services'

export default {
  name: 'SpamHintsAdmin',
  data () {
    return {
      isDialogAddOpen: false,
      isLoadingAdd: false,
      isDialogEditOpen: false,
      isLoadingEdit: false,
      isDialogDeleteOpen: false,
      isLoadingDelete: false,
      headers: [
        { text: 'Code', align: 'start', value: 'code' },
        {
          text: 'Hint',
          align: 'start',
          value: 'hint'
        },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      tableData: [],
      editedItem: {
        code: '',
        hint: ''
      },
      defaultItem: {
        code: '',
        hint: ''
      },
      newItem: {
        code: '',
        hint: ''
      },
      deleteId: undefined,
      rules: {
        required: (value) => !!value || 'Required.',
        noSpecialChars: (value) => {
          const pattern = /^[a-zA-Z0-9 _-]*$/
          return pattern.test(value) || 'No special characters allowed.'
        }
      }
    }
  },
  watch: {
    isDialogAddOpen (val) {
      val || this.closeAdd()
    },
    isDialogEditOpen (val) {
      val || this.closeEdit()
    },
    isDialogDeleteOpen (val) {
      val || this.closeDelete()
    }
  },
  created () {
    this.fetchSpamHints()
  },
  methods: {
    async fetchSpamHints () {
      const res = await spamHints.getAllSpamHints()
      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      }

      this.tableData = res
    },
    async saveAddSpamHint () {
      this.isLoadingAdd = true
      const res = await spamHints.createSpamHint({
        code: this.newItem.code,
        hint: this.newItem.hint
      })
      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      }
      await this.fetchSpamHints()
      this.isLoadingAdd = false
      this.closeAdd()
    },
    async saveEditSpamHint () {
      this.isLoadingEdit = true
      const res = await spamHints.editSpamHint({
        code: this.editedItem.code,
        hint: this.editedItem.hint
      })
      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      }
      await this.fetchSpamHints()
      this.isLoadingEdit = false
      this.closeEdit()
    },
    async deleteItemConfirm () {
      this.isLoadingDelete = true
      const res = await spamHints.deleteSpamHint({
        code: this.deleteId
      })
      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      }
      await this.fetchSpamHints()
      this.isLoadingDelete = false
      this.closeDelete()
    },
    editItem (item) {
      this.editedItem = Object.assign({}, item)
      this.isDialogEditOpen = true
    },
    deleteItem (item) {
      this.deleteId = item.code
      this.isDialogDeleteOpen = true
    },
    closeEdit () {
      this.isDialogEditOpen = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    closeAdd () {
      this.isDialogAddOpen = false
      this.$nextTick(() => {
        this.newItem = Object.assign({}, this.defaultItem)
      })
    },
    closeDelete () {
      this.isDialogDeleteOpen = false
      this.$nextTick(() => {
        this.deleteId = undefined
      })
    }
  }
}
</script>

<template>
  <v-data-table
    :headers="headers"
    :items="tableData"
    sort-by="id"
    disable-pagination
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Spam Hints Management</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        />
        <v-spacer />
        <v-dialog
          v-model="isDialogAddOpen"
          persistent
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Add Spam Hint
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Add Spam Hint</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="newItem.code"
                      :rules="[rules.required, rules.noSpecialChars]"
                      label="Code"
                      maxlength="255"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="newItem.hint"
                      :rules="[rules.required, rules.noSpecialChars]"
                      label="Hint"
                      maxlength="1000"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="isLoadingAdd"
                @click="closeAdd"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="isLoadingAdd"
                :disabled="isLoadingAdd"
                @click="saveAddSpamHint"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="isDialogEditOpen"
          persistent
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Edit Spam Hint</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="editedItem.code"
                      label="Code"
                      :rules="[rules.required, rules.noSpecialChars]"
                      maxlength="255"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="editedItem.hint"
                      :rules="[rules.required, rules.noSpecialChars]"
                      label="Hint"
                      maxlength="1000"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="isLoadingEdit"
                @click="closeEdit"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="isLoadingEdit"
                :disabled="isLoadingEdit"
                @click="saveEditSpamHint"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="isDialogDeleteOpen"
          persistent
          max-width="500px"
        >
          <v-card>
            <v-card-title class="text-h5">
              Are you sure you want to delete this spam hint?
            </v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="isLoadingDelete"
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="isLoadingDelete"
                :disabled="isLoadingDelete"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>
