var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      headers: _vm.headers,
      items: _vm.tableData,
      "sort-by": "id",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c(
              "v-toolbar",
              { attrs: { flat: "" } },
              [
                _c("v-toolbar-title", [_vm._v("Spam Hints Management")]),
                _c("v-divider", {
                  staticClass: "mx-4",
                  attrs: { inset: "", vertical: "" }
                }),
                _c("v-spacer"),
                _c(
                  "v-dialog",
                  {
                    attrs: { persistent: "", "max-width": "500px" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "mb-2",
                                    attrs: { color: "primary", dark: "" }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _vm._v(
                                  "\n            Add Spam Hint\n          "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.isDialogAddOpen,
                      callback: function($$v) {
                        _vm.isDialogAddOpen = $$v
                      },
                      expression: "isDialogAddOpen"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [
                          _c("span", { staticClass: "text-h5" }, [
                            _vm._v("Add Spam Hint")
                          ])
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            rules: [
                                              _vm.rules.required,
                                              _vm.rules.noSpecialChars
                                            ],
                                            label: "Code",
                                            maxlength: "255"
                                          },
                                          model: {
                                            value: _vm.newItem.code,
                                            callback: function($$v) {
                                              _vm.$set(_vm.newItem, "code", $$v)
                                            },
                                            expression: "newItem.code"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            rules: [
                                              _vm.rules.required,
                                              _vm.rules.noSpecialChars
                                            ],
                                            label: "Hint",
                                            maxlength: "1000"
                                          },
                                          model: {
                                            value: _vm.newItem.hint,
                                            callback: function($$v) {
                                              _vm.$set(_vm.newItem, "hint", $$v)
                                            },
                                            expression: "newItem.hint"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "blue darken-1",
                                  text: "",
                                  disabled: _vm.isLoadingAdd
                                },
                                on: { click: _vm.closeAdd }
                              },
                              [_vm._v("\n              Cancel\n            ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "blue darken-1",
                                  text: "",
                                  loading: _vm.isLoadingAdd,
                                  disabled: _vm.isLoadingAdd
                                },
                                on: { click: _vm.saveAddSpamHint }
                              },
                              [_vm._v("\n              Save\n            ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: { persistent: "", "max-width": "500px" },
                    model: {
                      value: _vm.isDialogEditOpen,
                      callback: function($$v) {
                        _vm.isDialogEditOpen = $$v
                      },
                      expression: "isDialogEditOpen"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [
                          _c("span", { staticClass: "text-h5" }, [
                            _vm._v("Edit Spam Hint")
                          ])
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Code",
                                            rules: [
                                              _vm.rules.required,
                                              _vm.rules.noSpecialChars
                                            ],
                                            maxlength: "255"
                                          },
                                          model: {
                                            value: _vm.editedItem.code,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editedItem,
                                                "code",
                                                $$v
                                              )
                                            },
                                            expression: "editedItem.code"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            rules: [
                                              _vm.rules.required,
                                              _vm.rules.noSpecialChars
                                            ],
                                            label: "Hint",
                                            maxlength: "1000"
                                          },
                                          model: {
                                            value: _vm.editedItem.hint,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editedItem,
                                                "hint",
                                                $$v
                                              )
                                            },
                                            expression: "editedItem.hint"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "blue darken-1",
                                  text: "",
                                  disabled: _vm.isLoadingEdit
                                },
                                on: { click: _vm.closeEdit }
                              },
                              [_vm._v("\n              Cancel\n            ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "blue darken-1",
                                  text: "",
                                  loading: _vm.isLoadingEdit,
                                  disabled: _vm.isLoadingEdit
                                },
                                on: { click: _vm.saveEditSpamHint }
                              },
                              [_vm._v("\n              Save\n            ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: { persistent: "", "max-width": "500px" },
                    model: {
                      value: _vm.isDialogDeleteOpen,
                      callback: function($$v) {
                        _vm.isDialogDeleteOpen = $$v
                      },
                      expression: "isDialogDeleteOpen"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", { staticClass: "text-h5" }, [
                          _vm._v(
                            "\n            Are you sure you want to delete this spam hint?\n          "
                          )
                        ]),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "blue darken-1",
                                  text: "",
                                  disabled: _vm.isLoadingDelete
                                },
                                on: { click: _vm.closeDelete }
                              },
                              [_vm._v("\n              Cancel\n            ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "blue darken-1",
                                  text: "",
                                  loading: _vm.isLoadingDelete,
                                  disabled: _vm.isLoadingDelete
                                },
                                on: { click: _vm.deleteItemConfirm }
                              },
                              [_vm._v("\n              OK\n            ")]
                            ),
                            _c("v-spacer")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "item.actions",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-icon",
              {
                staticClass: "mr-2",
                attrs: { small: "" },
                on: {
                  click: function($event) {
                    return _vm.editItem(item)
                  }
                }
              },
              [_vm._v("\n      mdi-pencil\n    ")]
            ),
            _c(
              "v-icon",
              {
                attrs: { small: "" },
                on: {
                  click: function($event) {
                    return _vm.deleteItem(item)
                  }
                }
              },
              [_vm._v("\n      mdi-delete\n    ")]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }